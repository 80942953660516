/* style */

.moving-letter {
	display: inline-block;
	cursor: default;
	background-color: var(--color-empty);
	text-shadow: 2px 2px var(--color-second), 3px 3px 3px var(--color-third);
}

.moving-letter-init {
	animation: rubberband 800ms forwards;
	visibility: hidden;
}

.moving-letter:hover {
	color: var(--color-second);
}

.moving-letter::selection {
	color: var(--color-empty);
}

.moving-letter-animation {
	animation: rubberband 800ms;
}

/* keyframes */

@keyframes rubberband {
	0%,
	100% {
		transform: scale3d(1, 1, 1);
		visibility: visible;
	}
	30% {
		color: var(--color-second);
		transform: scale3d(1.25, 0.75, 1);
		visibility: visible;
	}
	40% {
		color: var(--color-second);
		transform: scale3d(0.75, 1.25, 1);
		visibility: visible;
	}
	45% {
		color: var(--color-third);
		visibility: visible;
	}
	50% {
		transform: scale3d(1.15, 0.85, 1);
		visibility: visible;
	}
	65% {
		transform: scale3d(0.95, 1.05, 1);
		visibility: visible;
	}
	75% {
		transform: scale3d(1.05, 0.95, 1);
		visibility: visible;
	}
}

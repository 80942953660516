.icon-big {
	animation: showBigIcon 0.5s both;
}

.icon-regular {
	animation: showRegularIcon 0.5s both;
}

.text-big {
	animation: showBigText 0.5s both;
}

.text-regular {
	animation: showRegularText 0.5s both;
}

@keyframes showBigIcon {
	from {
		height: 1.5rem;
		width: 1.5rem;
	}
	to {
		height: 2rem;
		width: 2rem;
	}
}

@keyframes showBigText {
	from {
	}
	to {
		font-size: 1.5rem;
		line-height: 1.5rem;
	}
}

@keyframes showRegularIcon {
	from {
		height: 2rem;
		width: 2rem;
	}
	to {
		height: 1.5rem;
		width: 1.5rem;
	}
}

@keyframes showRegularText {
	from {
		font-size: 1.5rem;
		line-height: 1.5rem;
	}
	to {
	}
}

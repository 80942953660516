@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Montserrat:wght@200;400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--color-first: rgb(245, 245, 245);
	--color-second: rgb(240, 84, 84);
	--color-second-darker: rgb(240, 84, 84);
	--color-third: rgb(48, 71, 94);
	--color-fourth: rgb(18, 18, 18);
	--color-empty: rgb(255, 255, 255) 0;
}

* {
	color: var(--color-fourth);
	cursor: default;
	font-family: "Audiowide", cursive;
	margin: 0;
	padding: 0;
}

a,
button {
	cursor: pointer;
}

*::selection {
	color: var(--color-empty);
}

.bg-color-first {
	background-color: var(--color-first);
}

.bg-color-second {
	background-color: var(--color-second);
}

.bg-color-third {
	background-color: var(--color-third);
}

.bg-color-fourth {
	background-color: var(--color-fourth);
}

.bg-color-outline {
	box-shadow: -1px -1px 0 var(--color-fourth), 1px -1px 0 var(--color-fourth),
		-1px 1px 0 var(--color-fourth), 1px 1px 0 var(--color-fourth);
	background-color: var(--color-first);
}

.color-first,
.color-first * {
	color: var(--color-first);
}

.color-second {
	color: var(--color-second);
}
.color-second * {
	color: var(--color-second-darker);
}

.color-third,
.color-third * {
	color: var(--color-third);
}

.color-fourth,
.color-fourth * {
	color: var(--color-fourth);
}

.color-outline {
	color: var(--color-first);
	text-shadow: -1px -1px 0 var(--color-fourth), 1px -1px 0 var(--color-fourth),
		-1px 1px 0 var(--color-fourth), 1px 1px 0 var(--color-fourth);
}

.font-200 {
	font-weight: 200;
}

.font-400 {
	font-weight: 400;
}

.notification {
	animation: showNotification 0.5s both;
	position: fixed;
}

.notification-hide {
	animation: hideNotification 0.3s both;
	position: fixed;
}

.small-font {
	font-family: "Montserrat", sans-serif;
}

.p5Canvas {
	/* Override for p5 canvas */
	border: solid 1px var(--color-fourth);
	height: 95% !important;
	width: 95% !important;
}

.swiper-button-next,
.swiper-button-prev {
	/* Override for swiper arrows */
	color: var(--color-fourth) !important;
}

@keyframes hideNotification {
	from {
		bottom: 5.5rem;
		right: 1.5rem;
	}
	to {
		bottom: 5.5rem;
		right: -20rem;
		display: none;
	}
}

@keyframes showNotification {
	from {
		bottom: 5.5rem;
		right: -20rem;
	}
	to {
		bottom: 5.5rem;
		right: 1.5rem;
	}
}
